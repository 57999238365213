import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { EnumsOrgSubscriptionCancelOffer } from '@lp-lib/api-service-client/public';

import { SubscriptionCancelOfferAccepted } from '../components/Product/SubscriptionCancel';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  let offer = EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFirst;
  for (const o of Object.values(EnumsOrgSubscriptionCancelOffer)) {
    if (url.searchParams.get('offer') === o) {
      offer = o;
      break;
    }
  }

  return {
    offer,
  };
}

export function Component() {
  const { offer } = useLoaderData<typeof clientLoader>();

  return <SubscriptionCancelOfferAccepted offer={offer} />;
}
